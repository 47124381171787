<template>
  <div>
    <v-row class="mt-2">
      <v-col cols="6">
        <v-autocomplete
          :items="costCenters"
          label="Centro de costos"
          v-model="costCentersId"
          item-text="cc"
          item-value="cc"
          @change="handleCostCenterChange"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          :items="subCostCenters"
          label="Sub Centro de Costos"
          v-model="subCostCentersId"
          item-text="cod_scc"
          item-value="cod_scc"
          @change="handleSubCostCenterChange"
          :disabled="subCostCenters.length === 0"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          :items="campaign"
          label="Campaña"
          v-model="campaignId"
          item-text="campaign"
          item-value="campaign"
          @change="handleCampaing"
          :disabled="campaign.length === 0"
        >
          <template v-slot:prepend-inner>
            <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          prepend-inner-icon="mdi-code-block-tags"
          color="#466be3"
          :items="typeRequest"
          v-model="typeRequestId"
          item-text="name"
          item-value="id"
          label="Solicitud"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <vue-editor
          ref="editor"
          v-model="objetivo"
          :editor-toolbar="customToolbar"
        ></vue-editor>
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          prepend-inner-icon="mdi-account-box-multiple-outline"
          color="#466be3"
          :items="listUserAll"
          v-model="listUserAllId"
          item-text="user"
          item-value="id"
          label="Mantener en copia a"
          multiple
        >
          <template v-slot:selection="data">
            <v-chip small dark v-bind="data.attrs" color="#466be3">
              {{ data.item.full_name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="6">
        <v-select
          prepend-inner-icon="mdi-priority-high"
          color="#466be3"
          :items="priority"
          v-model="priorityId"
          item-text="name"
          item-value="id"
          label="Prioridad"
        >
        </v-select>
      </v-col>

      <v-col cols="6">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="150"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="selectedDate"
              label="Fecha esperada de entrega"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="selectedDate"
            :min="minDate"
            :allowed-dates="allowedDates"
            locale="es"
            @input="menu = false"
          >
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="6">
        <v-select
          prepend-inner-icon="mdi-account-outline"
          color="#466be3"
          :items="userDataArray"
          v-model="userDataArrayId"
          item-text="user"
          item-value="id"
          label="Solicitar a"
        >
        </v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          :rules="[(v) => !!v || 'Este campo es requerido']"
          v-model="statusId"
          item-text="name"
          item-value="id"
          :items="listStatus"
          label="Estados"
        ></v-select>
      </v-col>
      <v-col cols="6" style="border-radius: 15px">
        <v-btn
          class="mb-5 mt-2"
          dark
          block
          rounded
          color="#466BE3"
          @click="create()"
        >
          Guardar
        </v-btn>
      </v-col>
    </v-row>
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    Alert,
    VueEditor,
  },
  mixins: [color],
  data() {
    return {
      director: false,
      search: 0,
      loading: {
        table: false,
      },
      modal: {
        info: false,
      },
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loadingAll: false,
      costCenters: [],
      costCentersId: [],
      subCostCenters: [],
      subCostCentersId: [],
      campaign: [],
      campaignId: [],
      selectedDate: null,
      listUserAll: [],
      listUserAllId: [],
      menu: false,
      minDate: new Date(new Date().setDate(new Date().getDate() + 1))
        .toISOString()
        .substr(0, 10),
      typeRequest: [],
      typeRequestId: "",
      priority: [],
      priorityId: "",
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      objetivo: "<p></p>",
      listStatus: [
        {
          id: "2",
          name: "Borrador",
        },
        {
          id: "1",
          name: "Solicitar",
        },
      ],
      statusId: "",
      userDataArray: [],
      cedulasString: "",
      userDataArrayId: [],
      resultadoVerificacion: [],
    };
  },
  props: ["dataProcess"],
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    create() {
      var data = {
        centro_costo: this.mapingDataCc(),
        subcentro_costo: this.mapingDataScc(),
        campania: this.mapingDataCampaing(),
        fecha_estimada_req: this.selectedDate,
        solicitud_id: this.typeRequestId,
        mensaje: this.objetivo,
        user_id: this.currentUser.id,
        user_id_copia: this.listUserAllId,
        user_id_soporte: [this.userDataArrayId],
        prioridad_id: this.priorityId,
        status_id: this.statusId,
      };
      this.$emit("dataDewelopment", data);
    },
    listCostCenter() {
      Api.newDevelopment()
        .listCostCenter()
        .then((res) => {
          this.costCenters = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listSubCostCenter() {
      var data = {
        cc: [this.mapingDataCc()],
      };
      if (this.costCentersId.length === 0) {
        this.subCostCenters = [];
        this.campaign = [];
      } else {
        Api.newDevelopment()
          .listSubCostCenter(this.token, data)
          .then((res) => {
            this.subCostCenters = res.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    listCampaign() {
      if (this.subCostCentersId.length == 0) {
        this.campaign = [];
      } else {
        var data = {
          cc: [this.mapingDataCc()],
          scc: [this.mapingDataScc()],
        };
        Api.newDevelopment()
          .listCampaign(this.token, data)
          .then((res) => {
            this.campaign = res.data.data.filter(
              (item) => item.campaign !== null
            );

            // if (
            //   !this.campaignId.includes("Todos") &&
            //   this.campaign.length != 0
            // ) {
            //   this.campaign.unshift({ campaign: "Todos" });
            // }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleCostCenterChange() {
      if (this.costCentersId.includes("Todos")) {
        this.costCentersId = ["Todos"];
      }
      this.listSubCostCenter();
    },
    handleSubCostCenterChange() {
      if (this.subCostCentersId.includes("Todos")) {
        this.subCostCentersId = ["Todos"];
      }
      this.listCampaign();
    },
    handleCampaing() {
      if (this.campaignId.includes("Todos")) {
        this.campaignId = ["Todos"];
      }
    },
    mapingDataCc() {
      const isTodosSelected = this.costCentersId.includes("Todos");
      if (isTodosSelected) {
        return this.costCenters
          .map((costCenter) => costCenter.cc)
          .filter((costCenter) => costCenter !== "Todos");
      } else {
        return this.costCentersId;
      }
    },
    mapingDataScc() {
      const isTodosSelected = this.subCostCentersId.includes("Todos");
      if (isTodosSelected) {
        return this.subCostCenters
          .map((subCostCenter) => subCostCenter.cod_scc)
          .filter((subCostCenter) => subCostCenter !== "Todos");
      } else {
        return this.subCostCentersId;
      }
    },
    mapingDataCampaing() {
      const isTodosSelected = this.campaignId.includes("Todos");
      if (isTodosSelected) {
        return this.campaign.map((campaign) => campaign.campaign);
      } else {
        return this.campaignId;
      }
    },
    getUser(allUser) {
      this.listUserAll = allUser;
    },
    getAllUsersNickName() {
      let AllUser = this.$store.getters.getAllUsersNickName;
      let currentUser = this.$store.getters.getUser;
      this.currentUser = currentUser;
      this.getUser(AllUser);
    },
    getPriority() {
      Api.newDevelopment()
        .getPriority()
        .then((resp) => resp.json())
        .then((data) => {
          this.priority = data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          //   this.loading.table = false;
        });
    },
    getUserSuport() {
      Api.newDevelopment()
        .getUserSuport()
        .then((resp) => resp.json())
        .then((data) => {
          this.cedulasString = data.data[0].cedulas;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.mapCedulasToUserData();
        });
    },
    getRequestType() {
      Api.newDevelopment()
        .getRequestType()
        .then((resp) => resp.json())
        .then((data) => {
          this.typeRequest = data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          //   this.loading.table = false;
        });
    },
    getAllInfo() {
      this.getUserSuport();
      this.getPriority();
      this.getRequestType();
    },
    allowedDates(val) {
      const currentDate = new Date(val);
      currentDate.setHours(0, 0, 0, 0);

      return (
        currentDate >= new Date(this.minDate) &&
        [0, 1, 2, 3, 4].includes(currentDate.getDay())
      );
    },
    mapCedulasToUserData() {
      const cedulas = this.cedulasString
        .split("\r\n")
        .filter((cedula) => cedula.trim() !== "");

      this.userDataArray = cedulas.reduce((array, cedula) => {
        const userData = this.listUserAll.find(
          (data) => data.document_number === cedula
        );
        if (userData) {
          array.push(userData);
        }
        return array;
      }, []);

      if (this.dataProcess != undefined) {
        this.loadingAll=true;
        this.searchRequestedTo();
        this.searchCopieTo();
      }
    },
    clearData() {
      this.selectedDate = null;
      this.typeRequestId = "";
      this.objetivo = "";
      this.listUserAllId = "";
      this.userDataArrayId = [];
      this.priorityId = "";
      this.statusId = "";
      this.costCentersId = [];
      this.subCostCenters = [];
      this.subCostCentersId = [];
      this.campaign = [];
      this.campaignId = [];
    },
    searchData() {
      this.loadingAll=true;
      if (this.dataProcess != undefined) {
        this.selectedDate = this.dataProcess.fecha_estimada_req;
        this.typeRequestId = this.dataProcess.solicitud_id.toString();
        this.objetivo = this.dataProcess.mensaje;
        this.priorityId = this.dataProcess.prioridad_id.toString();
        this.statusId = this.dataProcess.status_id.toString();
        this.searchCenterCost();
      }
      this.loadingAll=false;
    },
    searchCenterCost() {
      this.loadingAll=true;
      this.costCentersId = this.dataProcess.centro_costo;
      this.handleCostCenterChange();
      this.subCostCentersId = this.dataProcess.subcentro_costo;
      this.handleSubCostCenterChange();
      this.campaignId = this.dataProcess.campania;
      this.handleCampaing();
      this.loadingAll=false;
    },
    searchRequestedTo() {
      this.loadingAll=true;
      const userEmail = this.dataProcess.user_id_soporte.match(/\((.*?)\)/);
      const userEmailString = userEmail ? userEmail[1].trim() : null;

      let resultadoVerificacion = this.userDataArray.find(
        (user) => user.email === userEmailString
      );
      if(resultadoVerificacion!=undefined){
      this.userDataArrayId = resultadoVerificacion.id;
      }
      this.loadingAll=false;
    },
    searchCopieTo() {
      this.loadingAll=true;
      const copiedUsers = this.dataProcess.user_id_copia
        .split(",")
        .map((entry) => entry.trim()) // Eliminar espacios en blanco alrededor de cada entrada
        .filter((entry) => entry !== ""); // Eliminar entradas vacías

      this.listUserAllId = copiedUsers.reduce((array, entry) => {
        const match = entry.match(/\((.*?)\)/); // Extraer correo electrónico entre paréntesis
        const userEmailString = match ? match[1].trim() : null;
        if (userEmailString) {
          const userData = this.listUserAll.find(
            (user) => user.email === userEmailString
          );
          if (userData) {
            array.push(userData.id);
          }
        }
        return array;
      }, []);
      this.loadingAll=false;
    },
  },
  created() {
    this.getToken();
    setTimeout(() => {
      this.getAllInfo();
      this.getAllUsersNickName();
      this.listCostCenter();
      this.searchData();
    }, 3000);
  },
  mounted() {
    this.$root.$on("procesoFinalizado", () => {
      this.clearData();
    });
  },
  beforeDestroy() {
    localStorage.removeItem("infoDraftDevelopment");
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.navidad-text {
  display: flex;
}

.reference__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 I !important;
}

tr,
th,
span .reference__table .text-start .sortable {
  font-size: 16px !important;
}

.reference__card {
  border-radius: 15px !important;
}

.reference__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.references__header {
  color: #353535 !important;
}
</style>
